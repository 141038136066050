<template>
  <div>
    <ModuleComponent :module-data="moduleData" @changed="item = $event">
      <template v-slot:form="{ item }">
        <b-row>
          <b-col cols="12" sm="12" md="12">
            <b-form-group :label="t('GLOBAL.NAME')">
              <b-form-input
                name="name"
                v-model="item.name"
                :placeholder="t('GLOBAL.NAME')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </ModuleComponent>
  </div>
</template>

<script>
// http://core.datadns.net:5000/swagger/index.html#model-Location
export default {
  name: "locations",
  data() {
    return {
      item: {},
      moduleData: {
        name: "locations",
        primaryKey: "id",
        table: {
          sortBy: "name",
          sortDesc: false,
          graphqlQuery: `
							id,name
						`,
          headers: [
            { text: "ID", value: "id", width: "70" },
            { text: this.t("GLOBAL.NAME"), value: "name" }
          ]
        },
        form: {
          maxWidth: "80%"
        }
      }
    };
  }
};
</script>
